<template>
  <section>
    <van-nav-bar title="首页" right-text="个人信息" @click-right="touser" />
    <div class="module">
      <div @click="tobuy" class="item faj">采购商品</div>
      <div @click="topay" class="item faj">
        <div>
          <div class="item_title">项目合同</div>
          <i class="item_text">(请先联系业务经理13022535287)</i>
        </div>
      </div>
      <div @click="tolist" class="item faj">查询订单</div>
      <div @click="todataList" class="item faj">合同列表</div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { NavBar } from "vant";
Vue.use(NavBar);
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    tobuy() {
      this.$router.push("buy");
    },
    touser() {
      this.$router.push("user");
    },
    topay() {
      this.$router.push("pay");
    },
    tolist() {
      this.$router.push("list");
    },
    todataList() {
      this.$router.push("dataList");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.module {
  width: 70%;
  margin: 30px auto 0 auto;
}
.item {
  height: 80px;
  color: #fff;
  background: #094771;
  border-radius: 8px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 30px;
}
.item_title{
  text-align: center;
  font-size: 20px;
}
.item_text{
  font-size: 14px;
}
</style>
